export const featuresPro = [
  '500 Credits/Day',
  'Custom Post Collections (Unlimited)',
  'Custom Daily Targets',
  'Active Days Leaderboard',
  'Comment Generation',
  '24/7 Customer Support',
  'Post Generation',
  'Custom Connection Note',
  'Custom Message Replies',
  'Generate Message Templates',
  'Track Active Days',
];
export const featuresFree = [
  '100 Credits/Day',
  'Custom Post Collections (Max 10)',
  'Daily Targets',
  'Comment Generation',
  'Post Generation',
  'Custom Connection Note',
  'Custom Message Replies',
  'Generate Message Templates',
  'Track Active Days',
];