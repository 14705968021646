export const features = [
  {
    heading: 'Tracks Active Days on LinkedIn',
    description: 'Gain insights into your LinkedIn activity. Monitor your presence and engagement over time.',
  },
  {
    heading: 'Create Posts Collections',
    description: 'Curate and categorize your posts according to themes, campaigns, or target audiences.',
  },
  {
    heading: 'Custom Connection Note',
    description: 'Make a lasting impression by personalizing your connection requests on LinkedIn.',
  },
];