    
    export const featureCards = [
      {
        title: 'Generate Comments',
        src: 'https://www.youtube.com/embed/8K7xou2gUk4?si=H7Wb9eLD1GrkUZnX',
        additionalClasses: 'top-14 translate-y-1',
      },
      {
        title: 'Generate Message Replies',
        src: 'https://www.youtube.com/embed/cfCRBYtAzFw?si=KmHwTC4GnucYSAi1',
        additionalClasses: 'lg:top-14 top-14 translate-y-1',
      },
      {
        title: 'Generate viral Posts',
        src: 'https://www.youtube.com/embed/rw-QI7jKVh0?si=ZV1u_r1uQ4PV_0ns',
        additionalClasses: 'top-8 translate-y-8',
      },
    ];
