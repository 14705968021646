export const faqs = [
  {
    "question": "What is EngageGPT Extension?",
    "answer": "EngageGPT Extension is a browser extension that enhances your LinkedIn experience by providing AI-powered tools for generating comments, posts, message replies, and more."
  },
  {
    "question": "How does the EngageGPT Extension work?",
    "answer": "The EngageGPT Extension utilizes advanced natural language processing (NLP) algorithms to analyze LinkedIn content and generate personalized responses, comments, and posts."
  },
  {
    "question": "Is the EngageGPT Extension free to use?",
    "answer": "Yes, the basic features of the EngageGPT Extension are available for free. However, there may be premium features or subscriptions available for additional functionalities."
  },
  {
    "question": "Can I customize the responses generated?",
    "answer": "Yes, the EngageGPT Extension allows users to customize and fine-tune the generated responses according to their preferences and style."
  },
  {
    "question": "Is the EngageGPT Extension safe to use?",
    "answer": "The EngageGPT Extension prioritizes user privacy and security. All data processing is performed locally on the user's device, and no personal information is stored or shared."
  }
]
