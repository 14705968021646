const topicOptions = [
  {
    value: 'description',
    label: 'Short Prompt',
    placeholder: 'Enter prompt here',
  },
  {
    value: 'paragraph',
    label: 'Paragraph',
    placeholder: 'Enter paragraph here',
  },
];

const templateOptions = ['Use Template', 'Use Persona'];
const tones = [
  'Friendly',
  'Humorous',
  'Emoji',
  'Formal',
  'Negative',
  'Serious',
  'Curious',
];

export { topicOptions, templateOptions, tones };
